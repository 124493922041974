<template>
  <div class="card card-custom">

  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
let cookie = require('vue-cookie');

export default {
  components: {  },
  computed: {
    ...mapGetters({
    })
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style>

</style>